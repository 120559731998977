<template>
	<!-- 产品需求-原始需求 -->
  <div class="container" style="padding: 20px;">
    <GModule>
			<div class="product">
				<a-tabs v-model:activeKey="activeKey" @change="tabChange" v-if="productArr.length > 0">
					<a-tab-pane v-for="(i,index) in productArr" :key="index" :tab="i.name"></a-tab-pane>
				</a-tabs>
				<div class="add" v-if="authority.addDate" @click="openCreattime()">
					<a-button shape="circle">
						<template #icon>
							<plus-outlined />
						</template>
					</a-button>
				</div>
			</div>
			<div class="condition-box" :data="listUrl">
        <template v-if="listUrl!='/demand/so_index'">
  				<div class="state-box">
  					<p>状态</p>
  					<a-dropdown>
  					    <template #overlay>
  					        <a-menu selectable @click="handleStatus">
  								<a-menu-item v-for="(nitem,nindex) in nstatusArr" :key="nindex">{{nitem}}</a-menu-item>
  					        </a-menu>
  					    </template>
  					    <a-button>
  						    {{nstatusIndex == 'all' ? '全部' : nstatusArr[nstatusIndex]}}
  				        <DownOutlined />
  					    </a-button>
  					</a-dropdown>
  				</div>
				<div class="state-box">
					<p>优先级</p>
					<a-dropdown>
					    <template #overlay>
					        <a-menu selectable @click="handleLevelClick">
								<a-menu-item v-for="(litm,lindex) in levelArr" :key="lindex">{{litm}}</a-menu-item>
					        </a-menu>
					    </template>
					    <a-button>
						    {{levelIndex == 'all' ? '全部' : levelArr[levelIndex]}}
				        <DownOutlined />
					    </a-button>
					</a-dropdown>
				</div>
  				<div class="state-box">
  					<p style="width: 60px;">创建时间</p>
  					<a-range-picker
  					    style="width: 400px"
  					    :ranges="ranges"
  					    show-time
  					    format="YYYY-MM-DD HH:mm:ss"
  						@change="change"/>
  				</div>
  				<a-button class="btn clo3" @click="search()">查询</a-button>
        </template>
				<div class="flx1"></div>
				<a-button class="btn clo1" v-if="authority.add" @click="goJump()">+ 提需求</a-button>
				<a-button class="btn clo2" v-if="authority.import">导入</a-button>
				<a-button class="btn clo2" v-if="authority.export" @click="exportModals=this.exportObj">导出</a-button>
				<a-modal v-if="exportModals" v-model:visible="exportModals" title="选择导出内容" @ok="exportExcl">
					<a-checkbox-group style="margin-bottom:5px;" v-model:value="exportValue" :options="exportModals" />
				</a-modal>



				<a-button class="btn clo3" v-if="authority.submit" @click="confirm(null,'submit')">批量提交</a-button>
				<a-button class="btn" type="danger" v-if="authority.del" @click="confirm(null,'del')">批量删除</a-button>
				<a-button class="btn clo6" v-if="authority.close" @click="confirm(null,'close')">批量关闭</a-button>

				<a-button class="btn clo1" v-if="authority.review" @click="confirm(null,'review')">批量通过</a-button>

				<a-modal v-if="modals" v-model:visible="omodals" :title="modals.title" @ok="confirm(modals.ids,modals.fun)" @cancel="modals=null">
					<div v-text="modals.name"></div>
				</a-modal>

			</div>
			<a-table 
				class="ttable" 
				:columns="columnshd" 
				:data-source="data" 
				:row-selection="{columnWidth:25, selectedRowKeys: rowSelection, onChange: onSelectChange }"
				bordered
				:pagination="false">
			  <template #bodyCell="{ column ,record,index}">
				  <template v-if="column.title === '需求ID'">
				  	<div class="dian">
				  		<p class="red_dian" v-if="authority.re_index&&record.review_status === 'fail'"></p>
				  		{{record.id}}
				  	</div>
				  </template>
				  <template v-if="column.dataIndex === 'priority_text'">
						<a-button size="small" type="danger" v-if="record.priority == 'urgent'">{{record.priority_text}}</a-button>
						<a-button size="small" class="btn clo2" v-else-if="record.priority == 'commonly'">{{record.priority_text}}</a-button>
						<a-button size="small" class="btn clo1" v-else-if="record.priority == 'post'">{{record.priority_text}}</a-button>
						<p v-else>未填写</p>
				  </template>
					<template v-if="column.title === '状态'">
						<p class="ptxtp" :style="(record.development_status_text||record.case_status_text||record.review_status_text||record.status_text) == 'fail' ? 'color:red;' : ''">{{record.development_status_text||record.case_status_text||record.review_status_text||record.status_text}}</p>
					</template>
					<template v-if="column.title === '需求描述'">
					  	<div class="ellipsis-rows">
							<a-tooltip placement="bottom" color="#ffffff" overlayClassName="overlayClassName">
						        <template #title>
						          <span style="color: #666666;">{{record.describe}}</span>
						        </template>
						        <p style="margin: 0;">{{record.describe}}</p>
						    </a-tooltip>
						</div>
					</template>
					<template v-if="column.title === '参数/逻辑说明'">
						<div class="ellipsis-rows">
							<a-tooltip placement="bottom" color="#ffffff" overlayClassName="overlayClassName">
						        <template #title>
						          <span style="color: #666666;">{{record.explain}}</span>
						        </template>
						        <p style="margin: 0;">{{record.explain}}</p>
						    </a-tooltip>
						</div>
					</template>

					<template v-if="column.title === '需求图片'">
						<div class="image-w-h" v-if="record.imgArr && record.imgArr.length > 0">
							<a-image :preview="false" :src="record.imgArr[0]" @click="openSwiper(record.imgArr)"/>
						</div>
					</template>



					<!-- 开发工时 -->
					<!-- <template v-if="column.key == 'front_hour'||column.key == 'back_hour'">
						<a-input-number
							v-if="data[record.index].edit[column.key]"
						  v-model:value="hourTxt"
						  :placeholder="column.edit"
						  type="number" 
						  :min="0"
						  :controls="false"
						  class="posInput" />
						<p v-else class="posInput" v-text="data[record.index][column.key]"></p>
						<form-outlined 
							v-if="!data[record.index].edit[column.key]" 
							class="ico" 
							@click="data[record.index].edit[column.key]=1,hourTxt=(data[record.index][column.key]||'')+''" />
						<a-popconfirm
							v-else
							title="保存此修改吗?"
							ok-text="确定"
							cancel-text="取消"
							@cancel="data[record.index].edit[column.key]=0,hourTxt=''"
							@confirm="editHour(column,record)">
							<form-outlined class="ico" />
						</a-popconfirm>
					</template> -->
					<template v-if="column.key == 'front_hour'">
						<!-- 前端工时 -->
						<a-input-number
						  v-model:value="record.front_hour"
						  placeholder="填写工时"
						  :min="0"
						  :controls="false" 
						  style="border: 0;text-align: center;"
						  @blur="editHour2(column,record,1)"/>
					</template>
					<template v-if="column.key == 'back_hour'">
						<!-- 前端工时 -->
						<a-input-number
						  v-model:value="record.back_hour"
						  placeholder="填写工时"
						  :min="0"
						  :controls="false" 
						  style="border: 0;text-align: center;"
						  @blur="editHour2(column,record,0)"/>
					</template>
					<!-- 开发工时 -->


					<template v-if="column.key === 'operation'">
						<div class="caozuo">

							<!-- 产品 -->
							<!-- <a-popconfirm
								v-if="authority.submit&&(record.status == 'wait' || record.status == 'fail')"
								title="确定提交此需求吗?"
								ok-text="确定"
								cancel-text="取消"
								:disabled="record.status!='wait'&&record.status!='fail'"
								@confirm="confirm(record.id,'submit')">
								<p :class="record.status!= 'wait'&&record.status!='fail'?'clo9':''">提交</p>
							</a-popconfirm> -->
							<p 
								v-if="authority.submit"
								:class="record.status =='wait' || record.status =='fail' ? '':'clo9'" 
								@click="record.status=='wait' || record.status=='fail' ? confirm(record.id,'submit') : false ">提交</p>
							
							
							
							<p v-if="authority.edit" :class="!record.can_edit?'clo9':''" @click="record.can_edit?updateClick(record,index):''">修改</p>

							<a-popconfirm
								v-if="authority.del"
								title="确定删除此需求吗?"
								ok-text="确定"
								cancel-text="取消"
								:disabled="!record.can_del"
								@confirm="confirm(record.id,'del')">
								<p :class="!record.can_del?'clo9':''">删除</p>
							</a-popconfirm>
							<!-- <a-popconfirm
								v-if="authority.close"
								:title="'确定'+(record.status=='close'?'恢复':'关闭')+'此需求吗?'"
								ok-text="确定"
								cancel-text="取消"
								:disabled="record.status!='pass'&&record.status!='completed'&&record.status!='close'"
								@confirm="confirm(record.id,'close')">
								<p :class="record.status!='pass'&&record.status!='completed'&&record.status!='close'?'clo9':''" v-text="record.status=='close'?'恢复':'关闭'"></p>
							</a-popconfirm> -->
							<p 
								v-if="authority.close"
								:class="record.status!='pass'&&record.status!='completed'&&record.status!='close'?'clo9':''" 
								v-text="record.status=='close'?'恢复':'关闭'"
								@click="record.status!='pass'&&record.status!='completed'&&record.status!='close' ? false : confirm(record.id,'close')"></p>
							
							<!-- 产品 -->

							<!-- 项目 -->
								<router-link v-if="authority.createTask&&record.status=='pass'" :to="'/TaskCreate?demandId='+record.id"><p>建任务</p></router-link>
							<!-- 项目 -->

							<!-- 测试 -->
								<router-link v-if="authority.addCases" :to="'/TheTestCaseAdd?demandId='+record.id"><p>建用例</p></router-link>
							<!-- 测试 -->

							<!-- 审阅 -->
								<!-- <a-popconfirm
									v-if="authority.review"
									title="确定通过此需求?"
									ok-text="确定"
									cancel-text="取消"
									:disabled="record.review_status=='pass'"
									@confirm="confirm(record.id,'review')">
									<p :class="record.review_status== 'pass'?'clo9':''">通过</p>
								</a-popconfirm> -->
								<p 
									v-if="authority.review" 
									:class="record.review_status== 'pass'?'clo9':''"
									@click="record.review_status== 'pass' ? false : confirm(record.id,'review')">通过</p>

								<p 
									v-if="authority.fail" 
									:class="record.review_status!= 'wait'&&record.review_status!='part'?'clo9':''" 
									@click="setFailModal(record)">驳回</p>
								<a-modal v-if="record.id==failModal" v-model:visible="failModal" title="驳回理由" @ok="failSubmit(failModal)">
									<a-form-item label="驳回理由">
										<a-input v-model:value="failText" placeholder="请输入驳回理由" />
									</a-form-item>
								</a-modal>
							<!-- 审阅 -->

							<router-link v-if="authority.getRow" :to="'/ProductDetail?id='+record.id"><p>详情</p></router-link>

						</div>
					</template>
			  </template>
			</a-table>
			<div class="documentFt" style="margin-top: 10px;">
			  <a-pagination size="small" :pageSize="limit" :total="total" :current="page" @change="pageChange"/>
			</div>



			<a-modal v-model:visible="isCreat" title="添加新一期需求" @ok="creattimeOk()">
				<!-- <div class="first1"> -->
					<p>是否确认添加新的一期需求？</p>
					<!-- <a-date-picker v-model:value="time" /> -->
				<!-- </div> -->
				<!-- <div class="first1">
					<p>类型</p>
					<a-dropdown>
					    <template #overlay>
					        <a-menu selectable @click="handleNameClick">
								<a-menu-item v-for="(item,index) in nameArr" :key="index" :disabled="index == 'original'">{{item}}</a-menu-item>
					        </a-menu>
					    </template>
					    <a-button>
							{{nameArr[nameIndex]}}
					        <DownOutlined />
					    </a-button>
					</a-dropdown>
				</div> -->
			</a-modal>
			<!-- 修改需求 -->
			<a-modal v-if="isUpdate" v-model:visible="isUpdate" title="修改" @ok="updateOk()">
				<a-form
					class="need-box"
				    :label-col="{ span: 6}"
				    :wrapper-col="{ span: 14}"
				    autocomplete="off"
					:scrollToFirstError='true'>
					<a-form-item label="需求描述">
					    <a-textarea placeholder="需求描述" style="height: 150px;" v-model:value="isUpdate.describe" />
					</a-form-item>
					<a-form-item label="参数/逻辑说明">
					    <a-textarea placeholder="参数/逻辑说明" style="height: 90px;" v-model:value="isUpdate.explain" />
					</a-form-item>
					<a-form-item label="需求图片">
					    <a-upload
							name="file"
							v-model:file-list="fileList"
							:maxCount='8'
							list-type="picture-card"
							:customRequest ="customRequest"
							@remove="remove">
							<div class="addimg" v-if="fileList.length < 8">
								<plus-outlined />
								<p style="color: #999999;">点击上传图片</p>
							</div>
					    </a-upload>
					</a-form-item>
				</a-form>
			</a-modal>
    </GModule>
  </div>
</template>
<script>
import dayjs from 'dayjs';

export default {
  name: 'Product',
  data(){
    return {
    	exportModals:null,
			hourTxt:'',
			exportValue:['date_name','modular_name','describe','explain'],
    	failModal:null,
    	failText:'',
    	modals: null,
	    productArr:[], // 需求tab标签页
			activeKey:0,
			nstatusArr:{},// 状态
			nstatusIndex:'all',
			levelArr:{} ,// 优先级列表
			levelIndex:'all',
			rowSelection:[],
			data: [],
			limit:10,
			page:1,
			createtime:'' ,// 创建时间
			ranges: {
				'今天': [dayjs().startOf('day'), dayjs().endOf('day')],
				'本周': [dayjs().startOf('week'), dayjs().endOf('week')],
				'本月': [dayjs().startOf('month'), dayjs().endOf('month')],
				'一个月前': [dayjs().subtract(1, 'month'),dayjs().endOf('day')],
			},
			total:0 ,// 总条数
			isCreat:false,
			nameArr:{} ,
			nameIndex:'add',
			time:'',
			// mId:0 // 模块id
			fileList:[] ,// 上传图片file列表
			imgs:[] ,// 已上传的图片
			isUpdate:null,// 修改
			updateId:0,//修改id
			describe:'',
			explain:'',
			updateIndex:0 ,// 修改的下标
			isLock:false // 锁定
    }
  },
  computed: {
		exportObj(){
			let arr = [{
				label:'需求类型',
				value:'date_name',
				disabled: true,
			},{
				label:'需求模块',
				value:'modular_name',
				disabled: true,
			},{
				label:'需求描述',
				value:'describe',
				disabled: true
			},{
				label: '参数/逻辑说明',
				value: 'explain',
				disabled: true
			}]
			if(this.activeKey!='原始需求'&&this.authority.edit_hour){
				arr = arr.concat([{
					label:'前端工时',
					value:'front_hour',
				}, {
					label:'后端工时',
					value:'back_hour',
				}])
			}else if(!this.authority.edit_hour){
				arr = arr.concat([{
					label:'状态',
					value:'status_text',
				},{
					label:'创建时间',
					value:'createtime_text',
				}])
			}
			return arr
		},
  	omodals(){
  		return !!this.modals
  	},
    selectedMemu(){
    	return this.$store.state.selectedMemu
    },
    proId(){
    	return this.$store.state.proId
    },
    authority(){
      var data = {};
      this.$store.state.params.forEach(item=>{
      	let name = item.name.split('/');
      	if(name[1]=='demand'){
	        data[name[2]] = true;
      	}else if(name[1]=='demand_date'){
	        data[name[2]+'Date'] = true;
      	}else if(name[1]=='cases'){
	        data[name[2]+'Cases'] = true;
      	}else if(name[1]=='task'){
	        data['createTask'] = true;
      	}
      })
      return data
    },
    listUrl(){
    	var data= this.$store.state.params.find(ite=>ite.title.indexOf('需求列表')>-1)||{};
    	return data.name? data.name.replace('api','') : ''
    },
    columnshd(){
    	var arr = [{
					title: '需求ID',
					width: 40,
					dataIndex: 'id',
					fixed: 'left'
				}, {
					title: '优先级',
					width: 50,
					dataIndex: 'priority_text'
				}, {
					title: '需求描述',
					width: 200,
					dataIndex: 'describe'
				}, {
					title: '需求图片',
					width: 120,
					dataIndex: 'images'
				}, {
					title: '参数/逻辑说明',
					width: 150,
					dataIndex: 'explain'
				}];
			if(this.authority.addCases){
				arr = arr.concat([{
				    title: '用例数',
				    width: 45,
				    dataIndex: 'case_num'
				  }, {
				    title: '状态',
				    width: 60,
				    dataIndex: 'case_status_text'
				  }, {
				    title: '操作',
				    key: 'operation',
				    fixed: 'right',
				    width: 90,
				}])
			}else if(this.authority.createTask){
				arr = arr.concat([{
				    title: '任务数',
				    width: 45,
				    dataIndex: 'task_num'
				  }, {
				    title: '状态',
				    width: 60,
				    dataIndex: 'development_status_text'
				  }, {
				    title: '操作',
				    key: 'operation',
				    fixed: 'right',
				    width: 90,
				}])// edit_hour
			}else if(this.authority.edit_hour){
				if(this.productArr&&this.productArr[this.activeKey]&&(this.productArr[this.activeKey].name!='原始需求')){
					arr = arr.concat([{
						  title: '前端工时(小时)',
						  width: 100,
						  dataIndex: 'front_hour',
					    key: 'front_hour',
					    edit:'填写工时',
						}, {
							title: '后端工时(小时)',
							width: 100,
							dataIndex: 'back_hour',
					    key: 'back_hour',
					    edit:'填写工时',
					}])
				}
				arr = arr.concat([{
					  title: '操作',
				    key: 'operation',
				    fixed: 'right',
				    width: 90,
				}])
			}else if(this.authority.review){
				arr = arr.concat([{
				    title: '状态',
				    width: 60,
				    dataIndex: 'review_status_text'
				  }, {
						title: '提审时间',
						width: 100,
						dataIndex: 'review_time_text'
				  }, {
				    title: '操作',
				    key: 'operation',
				    fixed: 'right',
				    width: 90,
				}])
			}else{
				arr = arr.concat([{
				    title: '状态',
				    width: 60,
				    dataIndex: 'status_text'
				  }, {
				    title: '创建时间',
				    width: 100,
				    dataIndex: 'createtime_text'
				  }, {
				    title: '操作',
				    key: 'operation',
				    fixed: 'right',
				    width:120,
				}])
			}
    	return arr
    },
		mId(){
			var id = 0;
			if(this.selectedMemu&&this.selectedMemu.length)id=this.selectedMemu[this.selectedMemu.length - 1].ids;
			return id
		}
  },
  watch:{
    proId(){
	  	this.onLoadx();
    },
    selectedMemu(){
    	if(this.selectedMemu.length){
				this.mId = this.selectedMemu[this.selectedMemu.length - 1].id
				if(this.mId){
					this.tabChange()
				}
    	}else{
    		this.mId = 0;
    	}
    },
    listUrl(){
    	if(this.listUrl)this.getList()
    }
  },
  created(){
  	console.log('params===',this.authority)
    // var authority = this.$route.params&&this.$route.params.data?JSON.parse(this.$route.params.data):[],
    //   data = {};
    // console.log('权限',authority)
    // // authority.forEach(item=>{
    // //   data[item.name.split('/')[2]] = true;
    // // })
    // this.authority = data;
  	this.onLoadx();
  },
  methods:{
  	// async editHour(column,record){
  	// 	if(!this.hourTxt){
  	// 		return this.$utils.msgErr('请输入工时！')
  	// 	}
  	// 	var data={
  	// 		ids:record.id,
  	// 		front_hour:record.front_hour,
			// 	back_hour:record.back_hour
  	// 	};
  	// 	data[column.key] = this.hourTxt;
			// let res = await this.$utils.api.post({
			// 	url:"/demand/edit_hour",
			// 	data:data,
			// 	result:1
			// })
			// if(res.code==1){
			// 	this.$utils.msgSuc('已提交')
			// 	this.data[record.index][column.key]=this.hourTxt+'';
			// 	this.data[record.index].edit[column.key]=0;
			// 	this.hourTxt = '';
			// }
  	// },
	async editHour2(column,record,type){
		
		if(type == 1 && !record.front_hour){
			this.$utils.msgWar('请填写正确的前端工时',1)
			return
		}
		if(type == 0 && !record.back_hour){
			this.$utils.msgWar('请填写正确的后端工时',1)
			return
		}

		var data={
			ids:record.id,
			front_hour:record.front_hour,
			back_hour:record.back_hour
		};
		let res = await this.$utils.api.post({
			url:"/demand/edit_hour",
			data:data,
			result:1
		})
		if(res.code==1){
			this.$utils.msgSuc('工时已提交')
		}
	},
  	setFailModal(record){
  		if(record.review_status=='wait'||record.review_status=='part'){
	  		this.failModal = record.id;
  		}
  	},
  	exportExcl(){
  		let arr = [];
  		for(var i in this.exportValue){
  			arr[i] = this.exportValue[i];
  		}
			this.$utils.api.downLoad({
				url:'/demand/export',
				data:{
          action_name:this.listUrl.split('/')[2],
					modular_id:this.mId||undefined,
					date_id:this.productArr[this.activeKey].id,
					fields:this.exportValue
				},
        name:'需求文件'
			})
  	},
  	async failSubmit(){
  		if(!this.failText){
  			return this.$utils.msgErr('请输入驳回理由！')
  		}
			let res = await this.$utils.api.post({
				url:"/demand/fail",
				data:{
					ids: this.failModal,
					msg: this.failText
				},
				result:1
			})
			if(res.code==1){
				this.$utils.msgSuc('已驳回')
				this.failModal = null;
				this.failText = '';
				this.getList()
			}
  	},
  	okmodals(fun,data){
  		this[fun](data);
  	},
  	async onLoadx(){
		if(!this.proId){
			return
		}
			// 初始化需求日期列表
			this.getTimeList()
			// 状态
			let res1 = await this.$utils.api.get({
				url:"/demand/setting",
				result:1
			})
			if(res1.code == 1){
				let a = {all:"全部"}
				this.levelArr = {...a,...res1.data.priorityList}
        if(this.authority.pr_index){
          this.nstatusArr = {...a,...res1.data.developmentStatusList}
        }else if(this.authority.test_index){
          this.nstatusArr = {...a,...res1.data.caseStatusList}
        }else if(this.authority.re_index){
					this.nstatusArr = {...a,...res1.data.reviewStatusList}
				}else{
					this.nstatusArr = {...a,...res1.data.statusList}
				}
			}
			// 获取类型
			let res2 = await this.$utils.api.get({
				url:'/demand_date/setting',
				result:1
			})
			if(res2.code == 1){
				this.nameArr =  res2.data.typeList
			}
			// 初始化产品需求列表
			this.getList()
  	},
	  handleStatus(e){
	  	this.nstatusIndex = e.key
	  },
	  handleLevelClick(e){
		  this.levelIndex = e.key
	  },
	  handleNameClick(e){
	  	this.nameIndex = e.key
	  },
	  openCreattime(e){
		  this.isCreat = true
		  // 重置为初始值
		  this.time = ''
		  this.nameIndex = 'add'
	  },
		async getTimeList(){
		  // 获取需求日期列表
		  let res = await this.$utils.api.post({
		  	url:"/demand_date/index",
			data:{
				sort:'id',
				order:'asc'
			},
		  	result:1
		  })
		  if(res.code == 1){
		  	this.productArr = res.data.rows
			if(this.productArr.length > 0){
				this.activeKey = this.productArr.length - 1
			}
		  }
		},
	  async creattimeOk(){
		  // 添加需求日期-提交
		  // if(this.time == ''){
			 //  this.$utils.msgWar('请选择创建时间')
			 //  return
		  // }
		  // let date = dayjs(this.time).format('YYYY-MM-DD')
		  let res = await this.$utils.api.post({
			  url:'/demand_date/add',
			  // data:{
				 //  'row':{
					//   'date':date,
					//   'type':this.nameIndex
				 //  }
			  // },
			  result:1
		  })
		  if(res.code == 1){
			  this.$utils.msgSuc('添加成功')
			  this.isCreat = false // 关闭弹窗
			  this.getTimeList() // 刷新需求日期
		  }
	  },
	  goJump(data){
			// 跳转提需求页面
			if(this.isLock){
				this.$utils.msgSuc('已锁定')
				return
			}
			let id = this.productArr.length > 0 ? this.productArr[this.activeKey].id : 0
			this.$router.push({
				name:'ProductNeed',
				query:{id:0}, 
				params:data?{data:JSON.stringify(data)}:null
			})
	  },
	  openSwiper(arr){
			// 弹出图片列表
			this.$store.commit('setSwiperArr',arr)
			this.$store.commit('setSwiper',1)
	  },
	  change(date,value){
	  	// 选择创建时间
	  	if(value[0] == '' || value[1] == ''){
	  		// 创建时间要同时满足开始时间和结束时间才可以查询
	  		this.createtime = ''
	  		return
	  	}
	  	let val = value.join(' - ') // 拼接日期
	  	this.createtime = val
	  },
	  pageChange(page,pageSize){
	  	// 切换页码时查询列表
	  	this.page = page
	  	this.getList()
	  },
	  search(){
	  	// 根据条件查询列表
	  	this.page = 1 //每次点击查询按钮都要重置页码为1
	  	this.getList()
	  },
	  async getList(){
		  if(!this.proId){
			  return
		  }
	  	// 请求列表
	  	let info = {
	  		limit: this.limit,
	  		page: this.page,
				op: {},
				filter: {}
	  	}
			if(this.mId){
				// 模块id
				info.op['modular_id'] = 'IN'
				info.filter['modular_id'] = this.mId
			}
			if(this.productArr&&this.productArr.length){
				// 需求日期id
				info.op['date_id'] = 'IN';
				info.filter['date_id'] = this.productArr[this.activeKey].id
			}
			if(this.nstatusIndex != 'all'){
				// 状态
        if(this.authority.pr_index){
          info.op['development_status'] = 'IN'
          info.filter['development_status'] = this.nstatusIndex
        }else if(this.authority.test_index){
          info.op['case_status'] = 'IN'
          info.filter['case_status'] = this.nstatusIndex
        }else if(this.authority.re_index){
					info.op['review_status'] = 'IN'
					info.filter['review_status'] = this.nstatusIndex
				}else{
					info.op['status'] = 'IN'
					info.filter['status'] = this.nstatusIndex
				}
			}
			if(this.levelIndex != 'all'){
				info.op['priority'] = 'IN'
				info.filter['priority'] = this.levelIndex
			}
			if(this.createtime){
				// 创建时间
				info.op['createtime'] = 'RANGE'
				info.filter['createtime'] = this.createtime
			}
			if(!this.listUrl)return;
	  	let res = await this.$utils.api.post({
	  		url: this.listUrl,
	  		data: info,
	  		result:1
	  	})
	  	if(res.code == 1){
	  		if(res.data.rows.length==0&&this.page>1){
	  			this.page--
	  			this.getList()
	  			return
	  		}
				// for(let i in res.data.rows){
				// 	res.data.rows[i].imgArr = res.data.rows[i].images.split(',')
				// }
				this.data = res.data.rows.map((item,idx) => {
					item.index = idx;
					item.imgArr =item.images.length > 0 ? item.images.split(',') : []
					item.key = item.id;

          if(this.authority.pr_index){
            if(item.status == 'close'){
              item.devolopment_status_text == item.status_text;
            }
          }
					if(this.authority.edit_hour){
						item.edit = {front_hour:0,back_hour:0};
					}
					return item
				})
	  		this.total = res.data.total
	  	}
	  },
	  tabChange(){
		  //点击tab切换,重置数据
		  if(this.activeKey != 0 && this.productArr[this.activeKey].status == 'lock'){
			 this.isLock = true  // 锁定本期后不可操作其他的功能
		  }else{
			  this.isLock = false 
		  }
		  this.nstatusIndex = 'all'
		  this.createtime = ''
		  this.levelIndex = 'all'
		  this.page = 1
		  this.getList()
	  },
	  onSelectChange(arr){
	  	this.rowSelection = arr;
	  },


		async confirm(ids,name){
				var type = {
					submit:'提交',
					del:'删除',
					close:'关闭',
					review:'审阅通过',
				};
				if(!ids){
					let arr = [];
					for(var i in this.rowSelection){
						arr.push(this.rowSelection[i])
					}
					ids=arr+'';
					if(!ids){
						this.$utils.msgErr('没有选中需求！')
					}else{
					this.modals = {
						title:'确认'+type[name]+'？',
						name:'是否确认'+type[name]+'ID为 '+ids+'的需求？'+(type=='submit'?'已提交过的不会重复提交':''),
						fun:name,
						ids:ids
					};
					}
					return
				}

				let res = await this.$utils.api.post({
						url:'/demand/'+name,
						data:{
							ids:ids
						},
						result:1
					})
				this.modals = null;
				if(res.code == 1){
					this.$utils.msgSuc(type[name]+'成功')
				  this.getList()
				}
	  },
	  async updateOk(){
		  if(this.isUpdate.describe == ''){
			  this.$utils.msgWar('请填写需求描述')
			  return
		  }
		  // if(this.isUpdate.explain == ''){
			 //  this.$utils.msgWar('请填写参数/逻辑说明')
			 //  return
		  // }
		  let info = {
				ids: this.isUpdate.id,
		  	'row':{
		  		'describe': this.isUpdate.describe,
		  		'explain': this.isUpdate.explain ? this.isUpdate.explain : '',
		  		'images': this.fileList.map(item=>item.url) +'',
		  	}
		  }
		  let res = await this.$utils.api.post({
		  	url:'/demand/edit',
		  	data:info,
		  	result:1
		  })
		  if(res.code == 1){
				this.$utils.msgSuc('修改成功')
				this.data[this.isUpdate.index].describe = this.isUpdate.describe+'';
				this.data[this.isUpdate.index].explain = this.isUpdate.explain+'';
				this.data[this.isUpdate.index].imgArr = this.fileList.map(item=>item.url)
				this.isUpdate = null;
				this.fileList = [];
		  }
		  
	  },
		async customRequest (e) {
			// 执行图片上传
			let that = this
			let res = await this.$utils.api.upLoad(e)
			if(res){
				setTimeout(()=>{
					that.$utils.msgSuc('上传成功')
					// 上传成功修改文件状态
					that.fileList[that.fileList.length-1].status = 'done' 
					// 图片封面预览
					that.fileList[that.fileList.length-1].url = res.data.fullurl 
					// 图片集合
					that.imgs.push(res.data.fullurl)
				},500)
			}
		},
		remove(e){
			// 删除图片
			this.isUpdate.imgArr = this.isUpdate.imgArr.filter((item) => {
			    return item != e.thumbUrl
			})
		},
	  updateClick(record,index){
		  // 弹出修改
		  this.isUpdate = { ...record, index:index };
		  // console.log(record)
		  // this.updateId = id
		  // this.updateIndex = index
		  // this.describe = describe
		  // this.explain = explain
		  // this.imgs = []
		  this.fileList = record.imgArr.map((item,idx)=>{
		  	return {
		  		uid:idx+1,
		  		name:item,
		  		status: 'done',
		  		url:item
		  	}
		  })
	  }
  }

}
</script>

<style scoped lang="scss">
.product{
	display: flex;
	.add{
		width: 40px;
		height: 40px;
		margin-left: 20px;
		.ant-btn-icon-only{
			width: 40px !important;
			height: 40px !important;
			padding:0 !important;
		}
	}
	.clo9{
		color:#999;
	}
}
.condition-box{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 5px 0 10px 0;
	.flx1{
		flex:1;
	}
	.state-box{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 5px;
		p{
			margin: 0 10px 0 20px;
		}
	}
	.btn{
		margin-left: 20px;
		margin-bottom: 5px;
	}
}
.ttable{
	margin-top:0;
	p.posInput{
		left:15px;
		margin-bottom:0;
	}
  .posInput{
    position: absolute;
    top:5px;
    left:5px;
    right:35px;
    bottom:5px;
    border:5px;
    width:auto;
    display: flex;
    align-items: center;
    &[disabled]{
    	background:transparent;
    }
    &~.ico{
    	position: absolute;
	    top:5px;
	    right:10px;
	    bottom:5px;
	    margin:auto;
	    font-size:16px;
	    height:16px;
	    color:#407cff;
    }
  }
}
.documentFt{
    margin-top:20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.caozuo{
	display: flex;
	justify-content: space-around;
	align-items: center;
	p{
		margin: 0;
		font-size: 12px;
		color: #407cff;
	}
	.clo9{
		color:#999;
	}
}
.first1{
	display: flex;
	padding: 30px 20px 0 20px;
	p{
		padding: 0;
		width: 100px;
		text-align: center;
	}
}
</style>